import { shop } from '@/types/graphql'
import { component_config } from '@/types/graphql'
import { TemplatePage, TemplatePageComponent } from '@/utils/types'
import { app_component } from '@/types/graphql';
import { icon, info, image } from '@/types/graphql';
import { replacePlaceholders } from '@/utils/data';

export const shopRest = async (r: string) =>
{

	try
	{

		const data = await fetch(`${process.env.REST_API_URL}/${r}`, {
			method: "GET",
			headers: {
				'Authorization': `Bearer ${process.env.API_TOKEN}`,
				'Content-Type': 'application/json; charset=UTF-8',
			},
			next: { revalidate: +(process.env.NEXT_REVALIDATE ?? 0) }
		});

		let txtResponse = await data?.text();
		txtResponse = await replacePlaceholders(txtResponse);
		const json = JSON.parse(txtResponse);

		let shop: shop | null = null;

		if (json['data'] && json['data'].length)
		{
			shop = json['data'][0];
		}

		return shop;

	} catch (e)
	{
		console.log(e)
	}

}

export const shopGql = async (query: string, id: Number) =>
{

	try
	{

		const data = await fetch(`${process.env.GRAPHQL_API_URL}`, {
			method: "POST",
			headers: {
				'Authorization': `Bearer ${process.env.API_TOKEN}`,
				'Content-Type': 'application/json; charset=UTF-8',
			},
			body: JSON.stringify({ "query": query, "variables": { "id": id } }),
			next: { revalidate: +(process.env.NEXT_REVALIDATE ?? 0) }
		});

		let txtResponse = await data?.text();
		txtResponse = await replacePlaceholders(txtResponse);
		const json = JSON.parse(txtResponse);

		let shop: shop | null = null;

		if (json['data'] && json['data']['shop'] && json['data']['shop'][0])
		{
			shop = json['data']['shop'][0];
		}

		return shop;

	} catch (e)
	{
		console.log(e)
	}

}

export const gql = async (query: string) =>
{

	try
	{
		let headers: any = {
			'Authorization': `Bearer ${process.env.API_TOKEN}`,
			'Content-Type': 'application/json; charset=UTF-8',
		}
		if (process.env.API_MASTER_TOKEN)
		{
			headers = {
				'x-hasura-admin-secret': process.env.API_MASTER_TOKEN,
				'Content-Type': 'application/json; charset=UTF-8',
			}
		}

		const data = await fetch(`${process.env.GRAPHQL_API_URL}`, {
			method: "POST",
			headers: headers,
			body: query,
			next: { revalidate: +(process.env.NEXT_REVALIDATE ?? 0) }
		});


		let txtResponse = await data?.text();
		// console.log(txtResponse)
		txtResponse = await replacePlaceholders(txtResponse);
		const json = JSON.parse(txtResponse);
		// console.log(json)

		// const json = await data?.json();

		return json;

	} catch (e)
	{
		console.log(e, query)
	}

}

export const getShopInfo = async (shop_id: number): Promise<info | null> =>
{

	try
	{

		const json = await gql(JSON.stringify({
			"query": `query ($shop_id:Int) {
					info(where: {id: {_eq: $shop_id}}){
						gtm_code
						ga_code
					}
				  }`, "variables": { "shop_id": shop_id }
		}));

		// console.log(json)

		if (json['data'] && json['data']['info'] && json['data']['info'][0])
		{
			const shopCfg: info = json['data']['info'][0];
			return shopCfg;
		}

		return null;

	} catch (e)
	{
		console.log(e)
	}

	return null;

}

export const getPageComponents = async (templatePage: TemplatePage) =>
{

	if (process.env.LOCAL_DEV == '1')
	{
		return templatePage;
	}

	// console.log('getPageComponents', templatePage.uid)

	try
	{


		const shopJson = await gql(JSON.stringify({
			"query": `query ($id: Int, $uid: String) {
				setting(where: {id: {_eq: $id}}) {
				  template {
					template_pages(where: {uid: {_eq: $uid}}) {
					  name
					  uid
					  components(order_by: {sort: asc}, where: {_and: {status: {_eq: 1}, app_component_id: {_is_null: false}, template_page_id: {_is_null: false}, parent_component_id: {_is_null: true}}}) {
					    id
						uid
						app_component {
						  adapter
						}
					  }
					}
				  }
				}
			  }`, "variables": { "uid": templatePage.uid, "id": parseInt(process.env.SHOP_ID ?? "0") }
		}));

		let componentList: TemplatePageComponent[] | null = null;

		if (shopJson['data'] && shopJson['data']['setting'] && shopJson['data']['setting'][0] && shopJson['data']['setting'][0]['template'] && shopJson['data']['setting'][0]['template']['template_pages'] && shopJson['data']['setting'][0]['template']['template_pages'][0] && shopJson['data']['setting'][0]['template']['template_pages'][0]['components'])
		{
			componentList = shopJson['data']['setting'][0]['template']['template_pages'][0]['components'];
		}

		if (componentList != null && typeof componentList.length != 'undefined')
		{
			templatePage.list = componentList;
			return templatePage;
		}

	} catch (e)
	{
		console.log(e)
	}

	return templatePage;
}

export const getChildComponents = async (parentComponentId: number): Promise<TemplatePageComponent[] | null> =>
{

	if (process.env.LOCAL_DEV == '1')
	{
		return null;
	}

	// console.log('getPageComponents', templatePage.uid)

	try
	{

		const json = await gql(JSON.stringify({
			"query": `query ($parent_component_id: Int) {
					component(order_by: {sort: asc}, where: {_and: {status: {_eq: 1}, app_component_id: {_is_null: false}, template_page_id: {_is_null: false}, parent_component_id: {_eq: $parent_component_id}}}) {
					id
					uid
					app_component {
						adapter
					}
			}
			}`, "variables": { "parent_component_id": parentComponentId }
		}));

		let componentList: TemplatePageComponent[] | null = null;

		if (json['data'] && json['data']['component'] && json['data']['component'].length)
		{
			componentList = json['data']['component'];
		}

		return componentList;

	} catch (e)
	{
		console.log(e)
	}

	return null;

}

export const getAppComponent = async (mod: TemplatePageComponent) =>
{

	try
	{

		let appComponent: Partial<app_component> | null = null;

		if (mod.is_app_component)
		{
			appComponent = {
				path: mod.uid
			}
			return appComponent;
		}

		const json = await gql(JSON.stringify({
			"query": `query ($uid:String) {
				component(where: {uid: {_eq: $uid}}){
				  app_component{
					path
				  }
				}
			  }`, "variables": { "uid": mod.uid }
		}));

		// console.log(json)

		if (json['data'] && json['data']['component'] && json['data']['component'][0] && json['data']['component'][0]['app_component'])
		{
			appComponent = json['data']['component'][0]['app_component'];
		}

		return appComponent;

	} catch (e)
	{
		console.log(e)
	}

}

export const getComponentConfig = async (mod: TemplatePageComponent) =>
{

	try
	{

		if (mod.is_app_component)
		{
			return null;
		}

		const json = await gql(JSON.stringify({
			"query": `query ($id: Int, $uid:String) {
				component_config(where: {_and:[{shop_id: {_eq: $id}},{uid: {_eq: $uid}}]}){
				  config
				}
			  }`, "variables": { "uid": mod.uid, "id": parseInt(process.env.SHOP_ID ?? "0") }
		}));

		let componentConfig: component_config | null = null;

		if (json['data'] && json['data']['component_config'] && json['data']['component_config'][0])
		{
			componentConfig = json['data']['component_config'][0];
		}

		if (componentConfig)
		{
			return componentConfig.config;
		} else
		{
			return null;
		}


	} catch (e)
	{
		console.log(e)
	}

}

export const getImage = async (imageId: Number) =>
{

	try
	{

		const json = await gql(JSON.stringify({
			"query": `query ($id: Int) {
				image(where: {id: {_eq: $id}}){
					name
					alt_text
					caption
					width
					height
					format
					mime
					size
					path
					webp
					blurhash
					placeholder
					type
					tag
					src
					library
				}
			  }`, "variables": { "id": imageId }
		}));

		let img: image | null = null;

		if (json['data'] && json['data']['image'] && json['data']['image'][0])
		{
			img = json['data']['image'][0];
		}

		if (img)
		{
			return img;
		} else
		{
			return null;
		}


	} catch (e)
	{
		console.log(e)
	}

}


export const getIconByTag = async (tag: string) =>
{

	try
	{

		const json = await gql(JSON.stringify({
			"query": `query ($tag: String) {
				icon(where: {tag: {_eq: $tag}}){
					src
					library
					tag
				}
			  }`, "variables": { "tag": tag }
		}));

		let ico: icon | null = null;

		if (json['data'] && json['data']['icon'] && json['data']['icon'][0])
		{
			ico = json['data']['icon'][0];
		}

		if (ico)
		{
			return ico;
		} else
		{
			return null;
		}


	} catch (e)
	{
		console.log(e)
	}

}
